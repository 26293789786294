<template>
  <footer class="footer">
    <div class="container">
      <p class="powered-by">Powered by SportsHub</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterChatbot",
};
</script>

<style scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  z-index: 1;
  display: flex;
  align-items: center;
  padding-left: 30px;
}

.footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20%;
  height: 1px;
  background: linear-gradient(to right, #0b731d, transparent);
}

.powered-by {
  color: #285e32;
  font-size: 14px;
  text-align: left;
}

/* Styles for screens that are less than 600px wide */
@media screen and (max-width: 599px) {
  .footer {
    height: 50px;
  }
}
</style>
