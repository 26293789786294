<template>
  <div class="language-selection">
    <select v-model="selectedLanguage" @change="setLanguage">
      <option value="en">English</option>
      <option value="da">Danish</option>
      <option value="no">Norwegian</option>
    </select>
  </div>
  <div v-if="!authenticated">
    <SignIn @authenticated="updateAuthenticated" />
  </div>
  <div v-else>
    <nav>
      <router-link to="/">{{ $t("myHub") }}</router-link> |
      <router-link to="/sportsbuddy">SportsBuddy</router-link> |
      <router-link to="/about">{{ $t("aboutRouter") }}</router-link>
    </nav>
    <router-view />
  </div>
  <div class="signout-button" v-if="authenticated">
    <button class="sign-out-button" @click="signOut">
      {{ $t("signOut") }}
    </button>
  </div>
  <FooterChatbot></FooterChatbot>
</template>

<script>
import SignIn from "@/components/SignIn.vue";
import FooterChatbot from "./components/Footer.vue";
import { mapState, mapGetters } from "vuex";
import * as msal from "msal";

export default {
  computed: {
    ...mapGetters(["myMSALObj"]),
    ...mapState(["preferredLanguage"]),
  },
  data() {
    return {
      authenticated: false,
      selectedLanguage: "en",
      myMSALObjSession: null,
    };
  },
  components: {
    SignIn,
    FooterChatbot,
  },
  watch: {
    preferredLanguage(newVal) {
      if (newVal !== "") {
        this.selectedLanguage = newVal;
        this.$i18n.locale = newVal;
      }
    },
  },
  methods: {
    updateAuthenticated(authenticated) {
      this.authenticated = authenticated;
    },
    setLanguage() {
      if (this.authenticated) {
        this.$store.commit("setPreferredLanguage", {
          preferredLanguage: this.selectedLanguage,
        });
      }
      this.$i18n.locale = this.selectedLanguage;
    },
    async signOut() {
      this.myMSALObjSession = new msal.UserAgentApplication(this.myMSALObj);
      this.myMSALObjSession.logout();
      this.authenticated = false;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #0b731d;
}

.language-selection {
  position: fixed;
  top: 10px;
  right: 10px;
}

.signout-button {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 2;
}

.language-selection select {
  font-weight: bold;
  color: #2c3e50;
  background-color: #ffffff;
  border: none;
  padding: 5px;
  border-radius: 3px;
}

.sign-out-button {
  background-color: #fff;
  color: #285e32;
  border: none;
  padding: 5px 15px;
  margin-left: 5px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 15px;
  transition: background 0.3s ease-in-out;
}

.sign-out-button:before {
  content: "";
  position: absolute;
  top: -2px;
  right: 0;
  bottom: -2px;
  left: 0;
  background: linear-gradient(to left, #0b731d, transparent);
  z-index: -1;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

.sign-out-button:hover:before {
  opacity: 1;
}
</style>
