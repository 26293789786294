<template>
  <div class="card">
    <button class="close-button" @click="closeCard">
      {{ $t("close") }}
    </button>
    <h2>{{ sportName }}</h2>
    <p>{{ $t("sportsInfo") }}</p>
    <button class="progress-button" @click="toggleCard">
      {{ $t("progressLogs") }} {{ isCardOpen ? "▲" : "▼" }}
    </button>
    <div v-show="isCardOpen">
      <button class="new-entry-button" v-show="isCardOpen" @click="newEntry">
        {{ $t("newEntry") }}
      </button>
      <div
        class="archive"
        v-if="currentSportsArea && currentSportsArea.logs.length > 0"
      >
        <div class="logs-container">
          <input
            class="filter-logs"
            v-model="logFilter"
            type="text"
            :placeholder="$t('filterLogs')"
          />
        </div>
        <div class="log" v-for="(log, index) in filteredLogs" :key="index">
          <div class="log-content" @click="startEditing(index)">
            {{ log.logEntry.substring(0, logLimit) + "..." }}
          </div>
        </div>
      </div>
      <br />
      <textarea
        :placeholder="$t('placeholderSportsCard')"
        v-show="isNewEntryOpen || editingIndex !== null"
        class="log-textarea"
        v-model="newLogEntry"
        maxlength="1500"
      ></textarea>
      <p v-if="isMaxLengthReached" class="warning">
        {{ $t("characterLimit") }}
      </p>
      <button
        class="save-button"
        v-if="isNewEntryOpen || editingIndex !== null"
        @click="saveProgress"
      >
        {{ $t("saveEntry") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import CryptoJS from "crypto-js";

export default {
  props: { sport: Object },
  data() {
    return {
      isCardOpen: true,
      newLogEntry: "",
      isNewEntryOpen: false,
      editingIndex: null,
      windowWidth: 0,
      logFilter: "",
    };
  },
  mounted() {
    this.updateWindowWidth();
    window.addEventListener("resize", this.updateWindowWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateWindowWidth);
  },
  computed: {
    ...mapGetters(["getSportName"]),
    ...mapState(["sportsAreas", "uniqueId", "preferredLanguage"]),
    sportName() {
      const sportKey = this.$store.state.sportNames[this.sport.id];
      return this.$t(sportKey);
    },
    logLimit() {
      // Adjust these values as needed
      if (this.windowWidth > 1200) return 120;
      if (this.windowWidth > 900) return 85;
      if (this.windowWidth > 599) return 50;
      return 35;
    },
    currentSportsArea() {
      return this.sportsAreas.find(
        (sportsArea) => sportsArea.id === this.sport.id
      );
    },
    filteredLogs() {
      const regex = new RegExp(this.logFilter, "i");
      return this.currentSportsArea.logs.filter((log) =>
        regex.test(log.logEntry)
      );
    },
    isMaxLengthReached() {
      return this.newLogEntry.length >= 1500;
    },
  },
  methods: {
    startEditing(index) {
      if (index >= 0 && index < this.currentSportsArea.logs.length) {
        this.editingIndex = index;
        this.newLogEntry = this.currentSportsArea.logs[index].logEntry;
      } else {
        console.error(`Invalid index: ${index}`);
      }
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
    closeCard() {
      this.$emit("close");
    },
    toggleCard() {
      this.isCardOpen = !this.isCardOpen;
    },
    encryptLogEntry(logEntry) {
      return CryptoJS.AES.encrypt(
        logEntry,
        process.env.VUE_APP_ENCRYPTION_KEY
      ).toString();
    },
    async saveProgress() {
      let sportsAreasCopy = JSON.parse(JSON.stringify(this.sportsAreas));
      let currentSportsAreaCopy = sportsAreasCopy.find(
        (area) => area.id === this.currentSportsArea.id
      );

      if (this.editingIndex !== null) {
        this.currentSportsArea.logs[this.editingIndex] = {
          logEntry: this.newLogEntry,
        };
        currentSportsAreaCopy.logs[this.editingIndex] = {
          logEntry: this.newLogEntry,
        };
      } else if (this.newLogEntry !== "") {
        this.currentSportsArea.logs.push({ logEntry: this.newLogEntry });
        currentSportsAreaCopy.logs.push({ logEntry: this.newLogEntry });
      } else {
        console.error("Invalid log entry");
      }

      // Encrypt all log entries across all sports areas
      sportsAreasCopy.forEach((area) => {
        area.logs = area.logs.map((log) => {
          return { logEntry: this.encryptLogEntry(log.logEntry) };
        });
      });

      this.newLogEntry = "";
      this.isNewEntryOpen = false;
      this.editingIndex = null;

      await fetch(`/api/PutPersona/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uniqueId: this.uniqueId,
          preferredLanguage: this.preferredLanguage,
          sportsAreas: sportsAreasCopy,
        }),
      });
    },
    newEntry() {
      this.editingIndex = null;
      this.newLogEntry = "";
      this.isNewEntryOpen = true;
    },
  },
};
</script>

<style scoped>
.archive {
  overflow: auto;
  max-height: 500px;
}

.log-textarea {
  width: 100%;
  max-width: 100%;
  min-height: 200px;
  /* adjust as needed */
}

.close-button {
  background-color: #f5f5f5;
  color: rgb(44, 62, 80);
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 5px;
  /* Adjust as needed */
  margin-right: 5px;
  /* Adjust as needed */
}

.log {
  border: 1px solid #ccc;
  margin: -10px 0;
  padding: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.card {
  position: fixed;
  width: 80%;
  height: 80%;
  /* Adjust to your preference */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  font-family: "Times New Roman", serif;
  line-height: 1.6;
  z-index: 10;
  overflow: auto;
}

.card h2 {
  margin: 0 0 10px;
}

.card p {
  margin: 0;
  margin-bottom: 10px;
}

.stats {
  background-color: #578d68;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
}

.save-button {
  background-color: #578d68;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 15px;
  max-width: 100%;
}

.progress-button {
  background-color: #578d68;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 15px;
  margin-top: 10px;
  margin-left: 5px;
}

.new-entry-button {
  background-color: #578d68;
  color: #fff;
  border: none;
  margin-top: 10px;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 15px;
  margin-bottom: 10px;
}

label {
  margin-right: 10px;
}

.logs-container {
  display: flex;
}

.filter-logs {
  margin-right: 10px;
  margin-bottom: 15px;
  width: 20%;
  border: none;
  background-color: #f5f5f5;
  border-bottom: 1.5px solid #578d68;
  outline: none;
  font-size: 16px;
}

@media (max-width: 599px) {
  .filter-logs {
    /* Add your styles for .filter-logs here */
    width: 65%;
  }

  .log-textarea {
    width: 100%;
    max-width: 100%;
    min-height: 100px;
    /* adjust as needed */
  }

  .archive {
    overflow: auto;
    max-height: 260px;
  }
}
</style>
